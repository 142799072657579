<div id="picker" class="zipcode-picker"
  [ngClass]="deviceService.getDeviceType().toLowerCase() === 'desktop' ? 'desktopHeight' : ''">
  <div class="zipcode-picker-body">
    <div class="zipcode-picker-body-upper">

      <div class="simple-select-wrapper simple-select-disable-reset">
        <label #countryLabel igxLabel class="simple-select-label simple-select-label--selected">Land:</label>
        <igx-simple-combo id="zipCodepicker" [data]="cData.countries" type="border"
          (opening)="combo.comboOpening(countryLabel)" (closing)="combo.comboClosing(countryLabel, pickerCountryCode)"
          displayKey="Name" valueKey="Code" [(ngModel)]="pickerCountryCode" (ngModelChange)="countryChange($event)"
          [style.--ig-size]="'var(--ig-size-small)'">
        </igx-simple-combo>
      </div>

      <igx-input-group type="border" [style.--ig-size]="'var(--ig-size-small)'">
        <input #fInput igxInput name="postalSearch" [(ngModel)]="postalSearchString" (focus)="onPostalSearchFocus()"
          (blur)="onPostalSearchBlur()" autocomplete="off" type="text" />
        <label igxLabel for="postalSearch">Sted:</label>
      </igx-input-group>

      <button class="find-case-button" id="submit" igxButton="contained" [style.color]="'white'" [ngStyle]="{
        'background-color': !isSearchDisabled ? '#AEC965' : '#D3D3D3',
        'color': !isSearchDisabled ? 'white' : '#A9A9A9'
      }" igxRipple="white" (click)="searchPostalAddresses()" [disabled]="isSearchDisabled">
        SØK
      </button>




    </div>
    @if(deviceService.getDeviceType().toLowerCase() !== 'desktop' && pickerDatasource.length === 0){
    <div class="empty-grid-search">
      <p>Søk på stedsnavn eller postnummer.</p>
    </div>
    } @else {
    <igx-grid igxPreventDocumentScroll #zipcodePickerGrid class="remove-shadows zipcode-picker-grid"
      [data]="pickerDatasource" [autoGenerate]="false" width="100%" [style.--ig-size]="'var(--ig-size-small)'"
      [cellSelection]="'none'" [rowSelection]="'single'" (cellClick)="onGridDobuleClick($event)" [isLoading]="isLoading"
      [emptyGridMessage]="searched ===true && pickerDatasource.length === 0 ? noResultsMessage : ' '"
      [hideRowSelectors]="true" [allowFiltering]="true">
      <igx-column width="30%" field="CountryCode" header="Landkode" [sortable]="true" [dataType]="'string'"
        [filterCellTemplate]="ZipCodeFilterTemplate"></igx-column>
      <igx-column width="30%" field="PostCode" header="Postnummer" [sortable]="true" [dataType]="'string'"
        [filterCellTemplate]="ZipCodeFilterTemplate"></igx-column>
      <igx-column width="40%" field="PostOffice" header="Poststed" [sortable]="true" [dataType]="'string'"
        [filterCellTemplate]="ZipCodeFilterTemplate"></igx-column>
      <ng-template #ZipCodeFilterTemplate igxFilterCellTemplate let-column="column">
        <grid-filter-input [grid]="zipcodePickerGrid" [column]="column"></grid-filter-input>
      </ng-template>
    </igx-grid>
    }


  </div>

</div>