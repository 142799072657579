import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { DatalexClient, ICountryBE, IPostalAddressBE } from '@datalex-software-as/datalex-client';
import { ContactDataService } from '../../pages/contact/contact-data.service';
import { IGridCellEventArgs, IgxColumnComponent, IgxGridComponent, IgxLabelDirective, IgxSimpleComboComponent, IgxInputGroupComponent, IgxInputDirective, IgxButtonDirective, IgxRippleDirective, IgxFilterCellTemplateDirective } from '@infragistics/igniteui-angular';
import { GridMethodsService } from 'src/app/services/grid-methods.service';
import { ComboSelectFunctionsService } from 'src/app/services/combo-select-functions.service';
import { GridFilterInputComponent } from '../grid-filter-input/grid-filter-input.component';
import { FormsModule } from '@angular/forms';
import { DeviceService } from 'src/app/services/device.service';
import { ActivityLogService } from '../activity-log-overlay/activity-log.service';
import newActivity from 'src/app/classes/Activity/Actvity';
import { CountryService } from 'src/app/services/country.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-zip-code-search',
  templateUrl: './zip-code-search.component.html',
  styleUrls: ['./zip-code-search.component.scss'],
  standalone: true,
  imports: [IgxLabelDirective, IgxSimpleComboComponent, FormsModule, IgxInputGroupComponent, IgxInputDirective, IgxButtonDirective, IgxRippleDirective, IgxGridComponent, IgxColumnComponent, IgxFilterCellTemplateDirective, GridFilterInputComponent, CommonModule]
})
export class ZipCodeSearchComponent implements OnInit {

  constructor(
    private dlxClient: DatalexClient,
    public cData: ContactDataService,
    public gms: GridMethodsService,
    public combo: ComboSelectFunctionsService,
    public deviceService: DeviceService,
    private als: ActivityLogService,
    private countryService: CountryService) { }


  @Input() pickerCountryCode: string = "NO";
  postalSearchString: string = "";
  @Input() pickerDatasource: IPostalAddressBE[] = [];

  @Output() notifyZipCodeSelection: EventEmitter<IPostalAddressBE> = new EventEmitter();


  countriesDS: ICountryBE[] = [];

  ngOnInit(): void {
    this.countriesDS = this.countryService.allCountries;

  }

  countryChange(event: any) {
    if (this.postalSearchString === "") return
    this.searchPostalAddresses(event);
  }

  searched: boolean = false;
  noResultsMessage: string = "Søket ga ingen treff.";

  isLoading: boolean = false;

  searchPostalAddresses(countryCode: string = this.pickerCountryCode) {

    if (!countryCode || !this.postalSearchString) {
      return;
    }
    this.isLoading = true;
    this.dlxClient.GetCountryByCode(countryCode).subscribe({
      next: (country) => {

        if (!country.Id) {
          this.isLoading = false;
          return;
        }

        this.dlxClient.FindPostalAddresses(this.postalSearchString, country.Id).subscribe({
          next: (postalAdresses) => {
            this.pickerDatasource = (postalAdresses);
            this.searched = true;
          },
          complete: () => {
            this.isLoading = false;
          }
        })
      },
      error: () => {
        const activity = newActivity({
          message: `Kunne ikke finne valgt land.`,
          type: "failure",
          timestamp: new Date()
        })

        this.als.appendActivity(activity)
        this.isLoading = false;
        return
      }
    })
  }


  onGridDobuleClick(event: IGridCellEventArgs) {
    this.notifyZipCodeSelection.emit(event.cell.row.data)
  }

  onPostalSearchFocus() {
    document.addEventListener('keydown', this.keyboardHandler)
  }
  onPostalSearchBlur() {
    document.removeEventListener('keydown', this.keyboardHandler)
  }


  keyboardHandler = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      this.searchPostalAddresses();
    }
  }


  get isSearchDisabled(): boolean {
    return !this.pickerCountryCode || !this.postalSearchString;
  }

}
