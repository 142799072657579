import { Component, OnInit, Input, Output, EventEmitter, inject, ViewChild } from '@angular/core';
import { IgxInputGroupComponent, IgxInputDirective, IgxLabelDirective, IgxSuffixDirective, IgxSimpleComboComponent, IgxDialogComponent, IgxDialogTitleDirective, ISimpleComboSelectionChangingEventArgs } from '@infragistics/igniteui-angular';

import { DatalexClient, IContactAddressBE, ICountryBE, IPostalAddressBE, UserAreaEnum, UserRightTypeEnum } from '@datalex-software-as/datalex-client';
import { CountryService } from 'src/app/services/country.service';
import { ScreenDimensionService } from 'src/app/services/screen-dimension.service';

import { EmptyGuid, RandomId } from 'src/app/util/RandomId';
import { ZipCodeSearchComponent } from '../zip-code-search/zip-code-search.component';
import { GridMethodsService } from 'src/app/services/grid-methods.service';
import { ComboSelectFunctionsService } from 'src/app/services/combo-select-functions.service';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SystemCacheService } from 'src/app/services/system-cache.service';
import { UserRightsProviderService } from 'src/app/services/user-rights-provider.service';

@Component({
  selector: 'dlx-address-input',
  templateUrl: './dlx-address-input.component.html',
  styleUrls: ['./dlx-address-input.component.scss'],
  standalone: true,
  imports: [IgxInputGroupComponent, FormsModule, IgxInputDirective, IgxLabelDirective, NgIf, IgxSuffixDirective, IgxSimpleComboComponent, IgxDialogComponent, IgxDialogTitleDirective, ZipCodeSearchComponent]
})
export class DlxAddressInputComponent implements OnInit {

  constructor() { 
  }

  randomId = RandomId();
  private sys = inject(SystemCacheService);
  private dlxClient = inject(DatalexClient);
  public screen = inject(ScreenDimensionService);
  public gms = inject(GridMethodsService);
  public countryService = inject(CountryService);
  public combo = inject(ComboSelectFunctionsService);
  private userRights = inject(UserRightsProviderService);

  _address: IContactAddressBE | null  = null;
  @Input() set address(val: IContactAddressBE | null) {
    this.contactAddress = val ? val : this.createEmptyAddress();
    this.zipCode = val && val.PostalAddress ? val.PostalAddress.PostCode : null;
    this.postArea = val && val.PostalAddress ? val.PostalAddress.PostOffice : null;
    this._address = val;
  }
  get address() { return this._address }
  @Input() country!: ICountryBE;
  @Input() addressType!: { Id: string, Name: string };
  @Input() countryCode: string | null = "NO";
  @Output() addressChange: EventEmitter<IContactAddressBE> = new EventEmitter();

  @ViewChild('zipCodeDialog') dialog!: IgxDialogComponent;


  public hasEditRights: boolean = false;

  _zipCode!: string | null;
  set zipCode(val: string | null) {
    if (val === "") { this.removePostalAddress(); return }
    if (val !== null) { this._zipCode = String(val.replace(/\D/g, '')); return; }
    this._zipCode = val;
  }
  get zipCode() { return this._zipCode }

  _postArea!: string | null;
  set postArea(val: string | null) {
    this._postArea = val;
    if (val === "") { this.removePostalAddress(); return }
  }
  get postArea() { return this._postArea }

  public contactAddress!: IContactAddressBE;

  showZipCode: boolean = true;

  ngOnInit(): void {
    this.contactAddress = this.address ? this.address : this.createEmptyAddress();
    this.zipCode = this.address && this.address.PostalAddress ? this.address.PostalAddress.PostCode : null;
    this.postArea = this.address && this.address.PostalAddress ? this.address.PostalAddress.PostOffice : null;
    
    try {
      this.hasEditRights = this.userRights.checkAccess(UserAreaEnum.CONTACTS).accessId.toUpperCase() === UserRightTypeEnum.FULL.toUpperCase();

    } catch (error) {
      this.sys.isReady.subscribe(() => {
        this.hasEditRights = this.userRights.checkAccess(UserAreaEnum.CONTACTS).accessId.toUpperCase() === UserRightTypeEnum.FULL.toUpperCase();
      })
    }
  }

  removePostalAddress() {
    this.contactAddress.PostalAddress = null;
    this.contactAddress.PostalId = null;
    this._zipCode = null;
    this._postArea = null;
    this.addressChange.emit(this.contactAddress);
  }

  createEmptyAddress(): IContactAddressBE {
    return {
      IsDeleted: false,
      IsNew: true,
      Id: EmptyGuid,
      AddressTypeId: this.addressType.Id,
      AddressType: this.addressType.Name,
      ContactId: EmptyGuid,
      Address: "",
      PostalId: null,
      Timestamp: [],
      PostalAddress: null,
    }
  }

  createEmptyPostalAddress(): IPostalAddressBE {
    return {
      IsNew: true,
      IsDeleted: false,
      Id: "",
      PostCode: "",
      PostOffice: "",
      CountryId: "",
      CountryName: "",
      CountryCode: "",
      Province: "",
      Order: 0,
      Timestamp: [],
      Country: this.country,
      Active: true,
      Code: ""
    }
  }

  validateZipCode(input: HTMLInputElement) {
    input.value = String(input.value.replace(/\D/g, ''));
  }

  onZipcodeSelection(event: IPostalAddressBE) {
    this.dialog.close();
    this.postArea = event.PostOffice;
    this.zipCode = event.PostCode;
    this.countryCode = event.CountryCode;
    this.contactAddress.PostalAddress = event;
    this.contactAddress.PostalId = event.Id
    this.addressChange.emit(this.contactAddress);
  }
  onZipCodeFocus() {
    document.addEventListener('keydown', this.lookupZipcodeKeyhandler)
  }
  onZipCodeBlur() {
    document.removeEventListener('keydown', this.lookupZipcodeKeyhandler)
  }

  lookupZipcodeKeyhandler = (event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      if (this.zipCode) {
        this.lookupZipcode(this.zipCode);
      } else {
        this.onShowDialog()
      }
    }
  }


  onCountryChange({ newSelection }: ISimpleComboSelectionChangingEventArgs) {
    this.country = newSelection;
    this.countryCode = newSelection.Code;
    this.dlxClient.FindPostalAddresses('', newSelection.Id).subscribe({
      next: (results) => {
        if(results.length === 0) {
          this.showZipCode = false;
          if (this.contactAddress.PostalAddress) {
            this.contactAddress.PostalAddress.IsDeleted = true;
            this.contactAddress.PostalId = null;
            this.addressChange.emit(this.contactAddress);
          }
        } else {
          this.showZipCode = true;
          this.postalAddreses = results;
        }

      }
    })
  }
  async onShowDialog() {
     
    this.dialog.open();
  }

  postalAddreses: IPostalAddressBE[] = [];
  lookupZipcode(zipcode: string | null) { 
    const postalAddress = this.postalAddreses.find(p => p.PostCode === zipcode);
    
    if(postalAddress) {
      this.postArea = postalAddress.PostOffice;
      this.zipCode = postalAddress.PostCode;
      this.countryCode = postalAddress.CountryCode;
      this.contactAddress.PostalAddress = postalAddress;
      this.contactAddress.PostalId = postalAddress.Id
      this.addressChange.emit(this.contactAddress);
      return
    }

    if(!zipcode) return;

    this.dlxClient.FindPostalAddresses(zipcode, this.country.Id).subscribe({
      next: (results) => {
        if(results.length === 1) {
          this.postArea = results[0].PostOffice;
          this.zipCode = results[0].PostCode;
          this.countryCode = results[0].CountryCode;
          this.contactAddress.PostalAddress = results[0];
          this.contactAddress.PostalId = results[0].Id
          this.addressChange.emit(this.contactAddress);
        } else {
          this.postalAddreses = results;
          this.dialog.open();
        }
      }
    })
  }

}
