<div class="address-wrapper flex-gap-18">
  <div class="div1">
    <igx-input-group type="border" [style.--ig-size]="'var(--ig-size-small)'">
      <textarea igxInput [name]="'address_' + randomId" autocomplete="off" type="text" rows="3" [(ngModel)]="contactAddress.Address"
        style="resize: none;margin-bottom: 18px;" [disabled]="!hasEditRights" (ngModelChange)="addressChange.emit(contactAddress)"></textarea>
      <label igxLabel>{{addressType.Name + ':'}}</label>
    </igx-input-group>
  </div>
  @if(showZipCode) {
  <div class="div2">
    <igx-input-group class="zipcode-input" type="border" [style.--ig-size]="'var(--ig-size-small)'">
      <input #zipcode igxInput name="zipCode" type="text" [(ngModel)]="zipCode" (focus)="onZipCodeFocus()"
        (input)="validateZipCode(zipcode)" (blur)="onZipCodeBlur()" autocomplete="off" [disabled]="!hasEditRights" />
      <label igxLabel for="zipCode">Postnr:</label>
      <igx-suffix class="material-icons" igxIcon (click)="lookupZipcode(zipCode)">search</igx-suffix>
    </igx-input-group>
  </div>
  <div class="div3">
    <igx-input-group class="postArea-input" type="border" [style.--ig-size]="'var(--ig-size-small)'">
      <input igxInput [name]="'postArea_' + randomId" type="text" [(ngModel)]="postArea" autocomplete="off"
        [disabled]="!hasEditRights" />
      <label igxLabel [for]="'postArea_' + randomId">Poststed:</label>
    </igx-input-group>
  </div>
  }
  <div class="div4 simple-select-wrapper simple-select-disable-reset">
    <label #countryLabel igxLabel class="simple-select-label simple-select-label--selected">Land:</label>
    <igx-simple-combo [data]="countryService.allCountries" type="border"
      (selectionChanging)="onCountryChange($event)" (opening)="combo.comboOpening(countryLabel)"
      (closing)="combo.comboClosing(countryLabel, countryCode)" displayKey="Name" valueKey="Code"
      [(ngModel)]="countryCode" [disabled]="!hasEditRights" [style.--ig-size]="'var(--ig-size-small)'">
    </igx-simple-combo>
  </div>

  


  <igx-dialog #zipCodeDialog [closeOnOutsideSelect]="true">
    <div igxDialogTitle>
      <div class="dialog-title-container">
        <div class="dialog-title">Finn postnummer:</div>
        <i translate="no" class="material-symbols-outlined close-icon" (click)="zipCodeDialog.close()">close</i>
      </div>
    </div>
    <div class="spaceless_dialog_body">
      <app-zip-code-search (notifyZipCodeSelection)="onZipcodeSelection($event)" [pickerDatasource]="postalAddreses" [pickerCountryCode]="countryCode ?? 'NO'" />
    </div>
  </igx-dialog>
</div>